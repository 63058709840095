<template>
	<div :class="propClass">
		<ShareNetwork
			v-for="network in networks"
			:key="network.network"
			:network="network.network"
			:url="shareUrl"
			:title="sharing.title"
			:description="sharing.description"
			:quote="sharing.quote"
			:hashtags="sharing.hashtags"
			class="text-center min-w-max inline-block bg-redDamask border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer py-2.5 px-5 hover:bg-redDamask-dark focus:bg-redDamask-dark active:bg-redDamask-dark"
		>
			<i :class="network.icon"></i>
			<span>{{ network.name }}</span>
		</ShareNetwork>
	</div>
</template>

<script>
export default {
	props: {
		shareUrl: {
			type: String,
			require: true,
		},
		propClass: {
			type: String,
			require: false,
			default: "",
		},
		lang: {
			type: String,
			require: false,
			default: "ro",
		}
	},
	data () {
		return {
			sharing: {
				// url: 'https://product.szmm.minic.dev',
				// title: '',
				// description: '',
				quote: this.$t('share.quote'),
				hashtags: this.$t('share.hashtags'),
			},
			networks: [
				// { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
				{ network: 'facebook', name: 'Facebook Share', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
			],
		}
	},
	 mounted() {
        this.$i18n.locale = this.lang;
    },
}
</script>