<template>
<div>
    <section v-if="mobile" class="h-screen overflow-hidden p-3 mx-2.5">
        <div class="mx-auto h-screen relative overflow-auto pb-24">
            <MobileHeader />
             <h1 class="font-heading text-h4 text-center text-sepiaBlack dark:text-white">{{ $t('timeline.title') }}</h1>
            <Button v-show="save_enabel == 'none' && !finished" buttonType="secondary" :text="$t('timeline.editBtn')" class="block text-center mx-auto my-2 mb-10" v-on:click="edit()"/>
            <div v-if="empty" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.emptyTimeline')" type='error' />
            </div>
            <div v-if="error" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.pinNotInDaily')" type='error' />
            </div>
            <div v-if="finishError" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.finishError')" type='error' />
            </div>
            <ul>
                <li v-if="selfies_mobile.length > 0" class="p-3 mx-2.5"> <p class="font-heading text-h6 text-sepiaBlack dark:text-white truncat">{{$t('timeline.selfie')}}</p></li>
                <div v-if="selfies_mobile.length > 0" class="gallery max-w-[95%] overflow-auto">
                    <Carousel :class="fullScreen ? '' : 'hidden'" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                        <Slide v-for="slide in selfies_mobile" :key="slide.img" v-bind:id="slide.id + '_1'">
                            <div class="absolute left-4 top-4 z-100">
                                <BaseIcon class="inline float-left mx-1" :outline="true" :bg="'border-white'" @click.prevent="removeFullscreen()" name="arrowLeftWhite" />
                            </div>
                            <img class="mx-auto w-full h-full p-2 rounded-2xl" :id="slide.id + '_img_1'" :ref="slide.id + '_1'"  v-bind:src="slide.img" alt="" style="object-fit:contain;">
                        </Slide>
                    </Carousel>
                    <Carousel class="slider-for" :settings="settings" :breakpoints="breakpoints" v-model="currentSlide">
                        <Slide v-for="(slide, index) in selfies_mobile" :key="slide.img" v-bind:id="slide.id + '_2'" @click="slideTo(index)">
                            <img :class="(fullScreen && index != currentSlide ? 'opacity60 ' : '') + 'px-1 rounded'" :id="slide.id + '_img_2'" :ref="slide.id + '_2'"  v-bind:src="slide.img" alt="" style="object-fit:contain;" @click.prevent="addFullscreen()">
                            <div class="absolute right-4 top-4 z-100">
                                <p v-if="save_enabel=='none'"></p>
                                <BaseIcon v-else-if="deleting_elements.includes(slide.id)" class="bg-white" name="checkmark" outlineColor="white" v-on:click="removeElementMobile(slide.id)" :size="'w-6 h-6'" :padding="'p-1'"/>
                                <BaseIcon v-else name="checkmark" outlineColor="white" v-on:click="addElementMobile(slide.id)" class="before-check" :size="'w-6 h-6'" :padding="'p-1'"/>
                            </div>
                        </Slide>
                    </Carousel>
                </div>
                <div  v-if="posts_mobile.length > 0">
                <li class="p-3 mx-2.5"> <p class="font-heading text-h6 text-sepiaBlack dark:text-white truncat">{{$t('timeline.entity')}}</p></li>
                <li v-for="(post) of posts_mobile" :key="post.id">
                    <EntityTimeLineMobile :content="post" :saveEnabel="save_enabel" :deletingElements="deleting_elements" @addElementMobile="addElementMobile" @removeElementMobile="removeElementMobile"/>
                </li> 
                </div>
            </ul>
            <p v-show="save_enabel == 'block'" class="block center text-center font-body text-bodyS text-sepiaBlack dark:text-white my-2"> {{deleting_elements.length + deleting_selfies.length}} {{ $t('timeline.selectedElements') }}</p>
            <Button v-show="save_enabel == 'block'" buttonType="primary" :text="$t('timeline.deleteBtn')" class="block center text-center my-2" v-on:click="edit()"/>
            <Button v-show="save_enabel == 'block'" buttonType="secondary" :text="$t('timeline.noDeleteBack')" class="block center text-center my-2" v-on:click="noDeleteBack()"/>
            <Button v-show="!error && !empty && !finished && save_enabel != 'block'" buttonType="primary" :text="$t('timeline.finishedBtn')" class="block text-center mx-auto my-2" v-on:click="finish()"/>
            <p v-show="!error && !empty && !finished && save_enabel != 'block'" class="block font-body text-bodyS text-redDamask text-center mb-10">{{ $t('timeline.finishInfo') }}</p>
            <Modal @btnDisabelChange="btnDisabelChange" class="hidden" @nextToFinish="nextToFinish" id="modal"/>
            <Share v-if="!error && !empty && finished" :shareUrl="share_url" propClass="pt-px block text-center mx-auto my-2 mb-10" :lang="postsData.lang"/>
            
        </div>
        <MobileNavigation :left="'0'"/>
    </section>
    <section v-else>
        <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto py-2 overflow-hidden">
            <Header :language="postsData.lang" @langchanged="langchanged_" :backArrow="true" :back="false">
            </Header>
            <div class="flex flex-row items-center">
                <h1 class="font-heading text-h1 text-halfColonialWhite ml-10"> {{ $t('timeline.title') }} </h1>
                <Button v-show="!finished" buttonType="primary" :text="$t('timeline.editBtn')" class=" ml-8" v-on:click="edit()"/>
                <Button v-show="!error && !empty && !finished" buttonType="primary" :text="$t('timeline.finishedBtn')" class=" ml-8" v-on:click="finish()"/>
                <Share v-if="!error && !empty && finished" :shareUrl="share_url" class="pl-4" :lang="postsData.lang" v-on:click="reload()"/>
            </div>
            
            <div v-if="empty" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.emptyTimeline')" type='error' />
            </div>
             <div v-if="error" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.pinNotInDaily')" type='error' />
            </div>
             <div v-if="finishError" class="text-center pt-10">
                <SystemMessage :text="$t('timeline.finishError')" type='error' />
            </div>
            <div class="flex overflow-auto" v-if="landscape">
                 <ul class="flex flex-nowrap mx-10 pb-10 pt-24 hide-scroll-bar relative">
                   <ul v-if="selfies.length > 0" class="inline-block relative min-w-80 w-80 border-t border-redDamask">
                    <span class="absolute block left-20 -top-24 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">01</span>
                    <p class="absolute block left-20 -top-8 font-heading text-h5 text-redDamask">{{$t('timeline.selfie')}}</p>
                    <span class="h-40 border-l border-redDamask block absolute left-20 top-0 transform -translate-x-1/2"></span>
                        <div class="gallery max-w-[95%] overflow-auto h-card overflow-ellipsis pt-28 rounded-lg mt-16 mb-8 px-2 relative block">
                            <Carousel :class="fullScreen ? '' : 'hidden'" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                                <Slide v-for="slide in selfies" :key="slide.img" v-bind:id="slide.id + '_1'">
                                    <div class="absolute left-4 top-4 z-100">
                                        <BaseIcon class="inline float-left mx-1" :outline="true" :bg="'border-white'" @click.prevent="removeFullscreen()" name="arrowLeftWhite"/>
                                    </div>
                                    <img class="mx-auto w-full h-full p-2 rounded-2xl" :id="slide.id + '_img_1'" :ref="slide.id + '_1'"  v-bind:src="slide.img" alt="" style="object-fit:contain;">
                                </Slide>
                            </Carousel>
                            <Carousel class="slider-for" :settings="settings" :breakpoints="breakpoints" v-model="currentSlide">
                                <Slide v-for="(slide, index) in selfies" :key="slide.img" v-bind:id="slide.id + '_2'" @click="slideTo(index)">
                                    <img :class="(fullScreen && index != currentSlide ? 'opacity60 ' : '') + 'px-1 rounded-lg'" :id="slide.id + '_img_2'" :ref="slide.id + '_2'"  v-bind:src="slide.img" alt="" style="object-fit:contain;" @click.prevent="addFullscreen()">
                                    <div class="absolute right-4 top-4 z-100">
                                        <p v-if="save_enabel=='none'"></p>
                                        <BaseIcon v-else-if="deleting_elements.includes(slide.id)" class="bg-white" name="checkmark" outlineColor="white" v-on:click="removeElementMobile(slide.id)" :size="'w-6 h-6'" :padding="'p-1'"/>
                                        <BaseIcon v-else name="checkmark" outlineColor="white" v-on:click="addElementMobile(slide.id)" class="before-check" :size="'w-6 h-6'" :padding="'p-1'"/>
                                    </div>
                                </Slide>
                            </Carousel>
                        </div>
                        <div class="flex items-center justify-center" @click="addFullscreen()">
                            <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite">{{$t('timeline.selfieFullscreen')}}</p>
                            <BaseIcon class="inline float-left mx-1" name="arrowRight" :outline="false"/>
                        </div>
                    </ul>
                    <li v-for="(post, index) in posts" :key="post.id" class="inline-block relative min-w-80 w-80 border-t border-redDamask">
                        <div class="absolute left-20 top-2 text-sepiaBlack dark:text-white" v-on:click="deleteElement(post.id, index)" :style="'display: ' +save_enabel+ ';'">
                            <BaseIcon name="closeRedDamask" :outline="false" />
                            <span class="absolute left-8 top-2"> {{ deleteBtn }} </span>
                        </div>
                        <div :class="'pt-' + marginGrid(index)">
                            <span v-if="selfies.length > 0" class="absolute block left-20 -top-24 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">{{ newIndex(index+1) }}</span>
                            <span v-else class="absolute block left-20 -top-24 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">{{ newIndex(index) }}</span>
                            <span :class="'h-' + lineHeight(index) + ' ' + 'border-l border-redDamask block absolute left-20 top-0 transform -translate-x-1/2' "></span>
                            <EntityTimeLine class="block" tv :content="post" :itemIndex="index" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="overflow-auto mt-12 not-mobile-portrait" v-else>
                <ul v-if="selfies.length > 0" class="mx-10 pl-24 relative">
                    <span class="absolute block left-8 top-4 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">01</span>
                    <p class="absolute block left-8 top-4 translate-y-[180%] font-heading text-h5 text-redDamask w-24">{{$t('timeline.selfie')}}</p>
                        <div class="gallery block relative border-l border-redDamask">
                            <span :class="fullScreen ? 'hidden' : ''" class="w-40 border-t border-redDamask block absolute left-0 top-20"></span>
                            <Carousel :class="fullScreen ? '' : 'hidden'" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                                <Slide v-for="slide in selfies" :key="slide.img" v-bind:id="slide.id + '_1'">
                                    <div class="absolute left-4 top-4 z-100">
                                        <BaseIcon class="inline float-left mx-1" :outline="true" :bg="'border-white'" @click.prevent="removeFullscreen()" name="arrowLeftWhite"/>
                                    </div>
                                    <img class="mx-auto w-full h-full p-2 rounded-2xl" :id="slide.id + '_img_1'" :ref="slide.id + '_1'"  v-bind:src="slide.img" alt="" style="object-fit:contain;">
                                </Slide>
                            </Carousel>
                            <Carousel :class="fullScreen ? 'portrait-full' : 'portrait-small' " class="slider-for" :settings="settings" :breakpoints="breakpoints" v-model="currentSlide">
                                <Slide v-for="(slide, index) in selfies" :key="slide.img" v-bind:id="slide.id + '_2'" @click="slideTo(index)">
                                    <img :class="(fullScreen && index != currentSlide ? 'opacity60 ' : '') + 'px-1 rounded-lg'" :id="slide.id + '_img_2'" :ref="slide.id + '_2'"  v-bind:src="slide.img" alt="" style="object-fit:contain;" @click.prevent="addFullscreen()">
                                    <div class="absolute right-4 top-4 z-100">
                                        <p v-if="save_enabel=='none'"></p>
                                        <BaseIcon v-else-if="deleting_elements.includes(slide.id)" class="bg-white" name="checkmark" outlineColor="white" v-on:click="removeElementMobile(slide.id)" :size="'w-6 h-6'" :padding="'p-1'"/>
                                        <BaseIcon v-else name="checkmark" outlineColor="white" v-on:click="addElementMobile(slide.id)" class="before-check" :size="'w-6 h-6'" :padding="'p-1'"/>
                                    </div>
                                </Slide>
                            </Carousel>
                        </div>
                        <div class="border-l border-redDamask flex items-center pl-40" @click="addFullscreen()">
                            <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite">{{$t('timeline.selfieFullscreen')}}</p>
                            <BaseIcon class="inline float-left mx-1" name="arrowRight" :outline="false"/>
                        </div>
                    </ul>
                <ul class="mx-10 pb-10 pl-24 relative">
                    <li v-for="(post, index) in posts" :key="post.id" class="block relative border-l border-redDamask pb-16">
                        <div class="absolute left-2 top-20 text-sepiaBlack dark:text-white" v-on:click="deleteElement(post.id, index)" :style="'display: ' +save_enabel+ ';'">
                            <BaseIcon name="closeRedDamask" :outline="false" />
                            <span class="absolute left-8 top-2"> {{ deleteBtn }} </span>
                        </div>
                        <div :class="'pl-' + marginGridPortre(index)">
                            <span v-if="selfies.length > 0" class="absolute block -left-16 top-4 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24	">{{ newIndex(index+1) }}</span>
                            <span v-else class="absolute block -left-16 top-4 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24	">{{ newIndex(index) }}</span>
                            <span :class="'w-' + lineHeightPortre(index) + ' ' + 'border-t border-redDamask block absolute left-0 top-20' "></span>
                            <EntityTimeLinePortre class="block" tv :content="post" :itemIndex="index" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</div>
</template>

<script>

import Header from "@/components/Header.vue";
import MobileHeader from "@/components/mobile/Header.vue";
import EntityTimeLine from "@/components/tablet/EntityTimeLine.vue";
import EntityTimeLineMobile from "@/components/mobile/EntityTimeLine.vue";
import EntityTimeLinePortre from "@/components/tablet/EntityTimeLinePortre.vue";
import Button from "@/components/Button.vue";
import MobileNavigation from "@/components/mobile/Navigation.vue";
import SystemMessage from '@/components/SystemMessage.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import Share from '@/components/Share.vue';
import Modal from '@/components/mobile/FinishTimelineModal.vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: "TimeLineEdit",
    components: {
        Header,
        MobileHeader,
        EntityTimeLineMobile,
        EntityTimeLinePortre,
        EntityTimeLine,
        Button,
        MobileNavigation,
        SystemMessage,
        BaseIcon,
        Share,
        Modal,
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    mounted() {
        this.pinCheck();
        this.getPosts();
        this.myEventHandler();
        this.$i18n.locale = this.language;
    },
    data(){
        return {
            posts: [],
            posts_mobile: [],
            selfies: [],
            selfies_mobile: [],
            timeline_id: 0,
            share_url: '',
            baseURL: process.env.VUE_APP_AXIOS_URL,
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            postsData: {
                user_pin: this.$route.query.user_pin ? this.$route.query.user_pin : (localStorage.getItem('user_pin') ? localStorage.getItem('user_pin') : null),
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            },
            deleting_elements: [],
            deleting_selfies: [],
            save_enabel: 'none',
            button_text: this.$t('timeline.editBtn'),
            mobile: false,
            landscape: true,
            empty: false,
            error: false,
            finishError: false,
            deleteBtn: this.$t('timeline.deleteSimple'),
            backBtn: this.$t('timeline.backBtn'),
            saveBtn: this.$t('timeline.saveBtn'),
            finished: false,
            btnDisabel: false,
            currentSlide: 0,
            fullScreen: false,
            settings: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            breakpoints: {
                400: {
                    itemsToShow: 5,
                    snapAlign: 'center',
                },
                // 700px and up
                700: {
                    itemsToShow: 8,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 10,
                    snapAlign: 'start',
                },
            },
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        myEventHandler() {
            if (window.innerWidth <= 768) {
                this.mobile = true;
            } else {
                this.mobile = false;
                if (screen.width > screen.height) {
                    this.landscape = true;
                } else {
                    this.landscape = false;
                }
            }
        },
        getPosts() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/last-timeline-pin`, {params: this.postsData}, {})
            .then(response => {
                if (response.data.error) {
                    this.error = true;
                } else if (!response.data.timeline_id) {
                    this.error = true;
                } else if (!((response.data.entities && response.data.entities.length > 0) || (response.data.selfies && response.data.selfies.length > 0))  ) {
                    this.empty = true;
                } else {
                    this.timeline_id = response.data.timeline_id;
                    this.share_url = window.location.origin + "/timeline/" + this.timeline_id;
                    this.posts = response.data.entities;
                    this.posts_mobile = response.data.entities;
                    this.selfies = response.data.selfies;
                    this.selfies_mobile = response.data.selfies;
                    if(response.data.timeline_finished) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }
                }
            }).catch( () => {
                // console.log(error);
            });
        },
        searchChange() {
            const search_input = document.querySelector('#quicksearchInput').value;
            if (search_input.length > 2 ) {
                this.posts_mobile = [];
                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].title.toLowerCase().includes(search_input.toLowerCase())) {
                        this.posts_mobile.push(this.posts[i]);
                    }
                }
            } else {
                this.posts_mobile = this.posts
            }
        },
        langchanged_(value) {
            this.postsData.page = 1;
            this.postsData.lang = value;
            this.getPosts();
        },
        btnDisabelChange() {
            document.querySelector("#modal").classList.add("hidden");
			this.btnDisabel = false;
		},
        nextToFinish() {
            this.finished = true;
            this.finishError = false;
            const post_variabels = {timeline_id: this.timeline_id, user_pin: this.postsData.user_pin};
            this.axios.post(this.baseURL + `/wp-json/wp/v2/timeline-finish`, post_variabels, {})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    this.finished = false;
                    this.finishError = true;
                }
            }).catch( () => {
                this.finished = false;
                this.finishError = true;
            });
            document.querySelector("#modal").classList.add("hidden");
            
        },
        gridRowCount(value) {
            return parseInt((value % 4)+1);
        },
        marginGrid(value) {
            return parseInt((value % 3) * 16);
        },
        lineHeight(value) {
            return parseInt(20 + ((value % 3) * 16));
        },
        newIndex: function(value) {
            if(value >= 9) {
                return value + 1;
            } 
            return `0${value+1}`
        },
        marginGridPortre(value) {
            return parseInt(((value % 2) + 1) * 20);
        },
        lineHeightPortre(value) {
            return parseInt(20 + ((value % 2) * 16));
        },
        deleteElement(value, index) {
            this.deleting_elements.push(value);
            this.posts.splice(index,1);
        },
        noDeleteBack() {
            this.deleting_elements = [];
            this.save_enabel = 'none';
        },
        addElementMobile(value) {
            this.deleting_elements.push(value);
        },
        removeElementMobile(value) {
            this.deleting_elements.splice(this.deleting_elements.indexOf(value), 1);
        },
        // deletingElementsCount(){
        //     return this.deleting_elements.length > 0 ? this.$t('timeline.deleteBtn') : this.$t('timeline.backBtn');
        // },
        edit() {
            if(this.posts.length > 0 || this.deleting_elements.length > 0 || this.selfies.length > 0 || this.selfies_mobile.length > 0) {
                if (this.save_enabel == 'none') {
                    this.save_enabel = 'block';
                    this.button_text = this.$t('timeline.saveBtn');
                    this.deleting_elements = [];
                } else {
                    // if (this.deleting_elements.length > 0) {
                        this.axios.delete(this.baseURL + `/wp-json/wp/v2/timeline_delete_elements`, {data: { 'user_pin': this.postsData.user_pin, 'entity_ids': this.deleting_elements}}, {})
                        .then(response => {
                            if (response.data.error) {
                                alert(response.data.error);
                                this.save_enabel = 'none';
                                this.button_text = this.$t('timeline.editBtn');
                            } else {
                                window.location.reload();
                            }
                        }).catch( () => {
                            this.save_enabel = 'none';
                            this.button_text = this.$t('timeline.editBtn');
                        });
                    // } else {
                    //     this.save_enabel = 'none';
                    //     this.button_text = this.$t('timeline.editBtn');
                    // }
                }
            }
        },
        finish() {
            document.querySelector("#modal").classList.remove("hidden");
        },
        pinCheck() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/pin_check`, {params: {'user_pin': this.postsData.user_pin}})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    this.$router.push('/pin-check');
                } 
            }).catch( (error) => {
                // console.log(error);
            });
        },
        reload() {
            window.location.reload();
        },
        addFullscreen (){
            if (this.save_enabel == 'none') {
                this.fullScreen = true;
                var gallery = document.querySelector('.gallery');
                document.querySelector('.sticy-header-background').classList.remove('z-50');
                gallery.classList.add('fullScreen');
                this.mobile ? '' : gallery.classList.add('notMobile');
                window.dispatchEvent(new Event('resize'));
            }
        },
        removeFullscreen () {
            this.fullScreen = false;
            var gallery = document.querySelector('.gallery');
            document.querySelector('.sticy-header-background').classList.add('z-50');
            gallery.classList.remove('fullScreen');
            this.mobile ? '' : gallery.classList.remove('notMobile');
            window.dispatchEvent(new Event('resize'));
        },
        slideTo(val) {
            this.currentSlide = val
        },
    },
    watch: {
        quicksearchInput: function(value) {
            // console.log(value);
            this.onChange();
        },
    },
};
</script>

<style>
    .slider-for svg.carousel__icon {
        display: none;
    }
    .slider-for button.carousel__prev {
        display: none;
    }
    .slider-for button.carousel__next {
        display: none;
    }
    .slider-nav .carousel__slide {
        opacity: 0.3;
    }
    .slider-nav .carousel__slide--active{
        opacity: 1;
    }
    .carousel__pagination {
        display: flex;
        justify-content: left;
        list-style: none;
        overflow: scroll;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .carousel__pagination::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .carousel__pagination {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .carousel__pagination-item {
        min-width: 8.375rem;
        min-height: 8.375rem;
        box-sizing: border-box;
    }
    .carousel__pagination-button {
        position: relative;
        width: 7.5rem;
        height: 7.5rem;
        opacity: 0.6;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .carousel__pagination-button:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        right: 2px;
        top: 2px;
        background: #fff;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65%;
    }
    .mediaIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/media.svg);
    }
    .imageIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/image.svg);
    }
    .soundIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/sound.svg);
    }
    
    .carousel__pagination-button--active {
        opacity: 1;
    }
    .gallery.fullScreen {
        position: fixed;
        z-index: 99;
        background: linear-gradient(180deg, #261710 43.23%, #3E2C0E 100%);
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
    }

    .gallery.notMobile {
        padding-top: 0;
        margin-top: 0;
        max-height: 100%;
        max-width: 100%;
    }

    .not-mobile-portrait .portrait-small.slider-for {
        max-width: 40vw;
        overflow: auto;
        margin-left: 160px;
    }

    .not-mobile-portrait .portrait-full.slider-for {
        max-width: 90vw;
        overflow: auto;
        margin: 0 auto;
    }

    .not-mobile-portrait .pl-20 {
        padding-left: 80px;
    }

    .fullScreen .carousel__pagination{
        justify-content: center;
    }
    .fullScreen .media_description, .fullScreen .media_holder {
        border-radius: unset;
    }
    .fullScreen .media_content{
        max-height: 85vh;
        height: 100%;
    }
    .buttons-hover:hover .buttons-holder{
        opacity: 1;
    }
    .gallery.fullScreen > .carousel > .carousel__viewport > .carousel__track{
        height: 70vh;
    }
    .gallery.fullScreen > .carousel.slider-for > .carousel__viewport > .carousel__track{
        height: 30vh;
    }
    .opacity60 {
        opacity: 0.6;
    }

    .slider-for .carousel__viewport {
        overflow: auto;
        width: 95vw;
    }

    @media screen and (max-width: 640px){
        .slider-for button.carousel__prev {
            top: calc(50% - 0px);
        }
        .slider-for button.carousel__next {
            top: calc(50% - 0px);
        }
    }
</style>
