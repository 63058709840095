<template>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 p-4 bg-bggt flex flex-col rounded-lg max-h-popup z-99" id="popupModal">
    <BaseIcon name="close" class="self-end" outline= false @click="closeModal"/>
    <h4 class="font-heading text-h4 text-white">{{ $t('timeline.finishBtnModalTitle') }}</h4>
    <p class="font-body text-bodyM text-white pt-2 pb-4 overflow-auto" v-html="translate.finishBtnModalText"></p>
    <Button :text="$t('timeline.finishedBtn')" buttonType="primary" fullWidth class="self-center pb-2" @click="next"/>
    <Button :text="$t('timeline.backBtn')" buttonType="secondary" fullWidth class="self-center pb-2" @click="closeModal"/>
  </div>
</template>

<script>

import Button from '@/components/Button.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'Modal',
  components: {
    Button,
    BaseIcon
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  computed: {
    translate() {
      return {
        finishBtnModalText: this.$t('timeline.finishBtnModalText'),
      }
    }
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  methods: {
    closeModal: function() {
      this.$emit('btnDisabelChange');
      document.querySelector("#popupModal").classList.add("hidden");
    },
    next: function() {
      this.$emit('nextToFinish');
      document.querySelector("#popupModal").classList.add("hidden");
    }  
  }
}
</script>